<template>
  <v-container class="claim-page grey-background">
    <v-row justify="center">
      <v-col cols="12" class="pt-8 pb-8 pl-9 grey-background">
        <div class="d-flex align-center justify-center">
          <v-img
            alt="Wallid Logo"
            class="shrink mr-1"
            contain
            src="../assets/logo-credential-issuers.png"
            transition="scale-transition"
            width="130"
          />
          <div class="beta_logo ml-3">
            <p>BETA</p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="shadow" justify="start" style="">
      <v-col cols="6" class="pb-0 pl-md-14 d-none d-sm-flex">
        <v-img
          class="shrink mr-1"
          contain
          src="../assets/imgs/badge-crypto-cafe.png"
          srcset="../assets/imgs/badge-crypto-cafe@2x.png 2x ../assets/imgs/badge-crypto-cafe@3x.png 3x"
          transition="scale-transition"
          width="425"
        />
      </v-col>
      <v-col cols="12" md="6" class="pt-8 px-md-12 justify-center">
        <h1 class="title_header text-left mb-4">
          Dá claim ao teu badge CryptoCafé
        </h1>
        <p
          class="subtitle_header"
          style="
            font-size: 16px;
            font-weight: 500;
            line-height: 30px;
            max-width: 390px;
          "
        >
          Aqui está o tão aguardado Badge da 1ª geração do Gang Do CryptoCafe.
          Deixa o teu nome e email para receberes o teu Badge personalizado e
          mostra ao mundo quem é o verdadeiro OG da Crypto Tuga.
        </p>
        <v-form ref="form" @submit.prevent="sendEmail" lazy-validation style="">
          <v-row>
            <v-col cols="12" md="11" class="input-field pb-0 pr-md-10">
              <label class=""> Nome</label>
              <v-text-field
                v-model="name"
                class="mt-2"
                flat
                :rules="nameRules"
                solo
              ></v-text-field>
              <label class=""> E-mail </label>
              <v-text-field
                v-model="email"
                class="mt-2"
                flat
                :rules="emailRules"
                solo
              ></v-text-field>
            </v-col>
            <v-btn type="submit" class="next mt-4" :disabled="loading">
              {{ buttonText }}
            </v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <app-footer />
  </v-container>
</template>

<script>
// import { mapGetters } from "vuex";
import AppFooter from '../layout/AppFooter';

import axios from 'axios';

export default {
  name: 'Invite',
  components: {
    AppFooter,
  },
  watch: {},
  created() {
    this.debug(`*** ${this.$options.name} ***`);
  },
  mounted() {
    this.$store.commit('isLoading', false);
  },
  destroyed() {},
  computed: {},
  methods: {
    sendEmail() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let email = this.email;
        let name = this.name;

        const BASE_URL =
          process.env.VUE_APP_API_URL + '/user/claim-credentials';

        let body = {
          email,
          name,
        };
        axios
          .post(BASE_URL, body)
          .then((res) => {
            console.log(res);
            this.buttonText = 'Badge Claimed!';
            this.loading = false;
            setTimeout(() => {
              this.buttonText = 'Claim Badge';
            }, 3000);
          })
          .catch((error) => {
            this.loading = false;
            console.error(error);
          });
      }
    },
  },
  data() {
    return {
      buttonText: 'Claim Badge',
      loading: false,
      email: null,
      name: null,
      errorName: null,
      errorEmail: null,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length >= 3) || 'Name should be at least 3 characters',
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    };
  },
};
</script>

<style lang="scss">
.dca-verify-web.theme--light.v-application {
  background: #eeeeee !important;
}
.claim-page {
  max-width: 1194px;
  .col {
    background-color: #ffffff;
  }
  .grey-background {
    background-color: #eeeeee;
  }
  .shadow {
    box-shadow: 0 2px 4px 0 #b8b9bb;
    border-radius: 5px;
    padding: 12px;
    background-color: #ffffff;
    padding-bottom: 120px;
    @media screen and (min-width: 600px) {
      padding: 0 40px 40px 40px;
    }
  }
  ul {
    list-style-position: outside;
  }
  .v-form {
    display: flex;
    .input-field {
      .v-input {
        max-width: 360px;
      }
      .v-input--radio-group {
        .v-input__slot {
          border: none !important;
        }
      }
      .v-input__control {
        min-height: unset;
        .v-input__slot {
          margin-bottom: 4px;
          height: 44px;
          border-radius: 3px;
          border: solid 1px var(--light-grey-blue);
        }
        .v-text-field__details {
          margin-bottom: 4px;
        }
      }
    }
  }
  .next.v-btn {
    width: 100%;
    max-width: 360px;
    height: 48px !important;
    padding: 6px 12px !important;
    margin-left: 12px;
  }
  .email-input {
    min-width: 70%;
    .v-input__slot {
      border: solid 1px var(--teal-blue);
    }
    .v-text-field__details {
      position: relative;
      top: -75px;
    }
    &.error--text {
      .v-text-field__slot {
        font-size: 16px;
      }
    }
  }
  .footer_wallid {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .stepper-signup {
    box-shadow: none;
  }
  .sign_footer {
    .links {
      font-size: 12px !important;
    }
  }
}
</style>
